import { MultipleFieldErrors } from 'react-hook-form'

import { AlertCircle, HelpCircle } from '@fsg/icons'

type Params = { message: string; messages?: MultipleFieldErrors }

/**
 * Helper function to be passed to the `render` prop of react-hook-forms ErrorMessage component.
 *
 * @param message - An object containing the error messages from React Hook Forms.
 * @returns {JSX} - A JSX Element containing an error message.
 */
export const renderErrorMessage = ({ message, messages }: Params) => {
  return message ? (
    <div className="mt-xs rounded-lg bg-[#fee6e9] px-2xs py-3xs text-xs text-color-error">
      <div className="flex gap-3xs">
        <div className="pt-[2px]">
          <AlertCircle className="h-[14px] w-[14px]" />
        </div>
        <span>{message}</span>
      </div>
    </div>
  ) : null
}
