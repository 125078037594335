import { FilterModel, GridApi, IServerSideGetRowsParams } from '@ag-grid-community/core'

import { EntityApiQueryParams } from '@app/types'

import { GetByParamsResponseType, useEngageServiceFactory } from '../hooks/useEngageServiceFactory'
import { convertFilterModelToApiQuery } from './convertFilterModelToApiQuery'

// "Server Side Row Model" Success Callback
export type SSRMSuccessCallback<ResponseType> = {
  queryParams: EntityApiQueryParams
  response: GetByParamsResponseType<ResponseType>
  filterModel: FilterModel
}

export type SSRMErrorCallback = {
  error: Error | null
}

type Args<ResponseType> = {
  // Used for fetching the data via getByParams
  service: ReturnType<typeof useEngageServiceFactory<any, ResponseType>>
  // After the data is fetched, you may specify a callback to do something with the query params, response, or filterModel
  onSuccess?: (args: SSRMSuccessCallback<ResponseType>, ...extraArgs: any[]) => any
  // After an error, you may specify a callback to do something with the thrown exception
  onError?: (args: SSRMErrorCallback, ...extraArgs: any[]) => any
  // Mutate the query params after they have been built by `builtQueryParams` and before the request is made
  transformParams?: (args: ReturnType<typeof buildQueryParams>) => EntityApiQueryParams
  fieldArray?: {data: any[], key: string}
  setResponseLength?: React.Dispatch<React.SetStateAction<number>>
}

export function buildQueryParams(params: IServerSideGetRowsParams) {
  /*
    * Engage API Pagination:
    For a given data set of size X, 
     T = total pages
    `limit` = the page size, AKA how many records are returned in a page
    `page` = which page will be returned
    
    By default, a request without pagination parameters will return the first 25 records in the collection.
    The API returns `{ data, page, limit, totalCount }`.
    
    Consider this query performed on a collection of 1000 records: `{path}?page={3}&limit={100}
    This means that:
    X = 1000 = totalCount
    limit = 100
    page = 3
    T = 10 -> totalCount/limit

    Thus, records 300-399 are returned (page 3 of 10)
  */

  // When the grid is initially loaded, `startRow` is set to 0
  // startRow and endRow are calculated based on the grid's `cacheBlockSize`, which defaults to 100.
  // thus we can use `endRow - startRow` to calculate the number of records we want in a page from the API, aka the `limit`
  const limit = params.request.endRow - params.request.startRow
  // then, we need the page number, which is equal to the startRow / limit, then add 1 to account for the 1-based indexing of pages
  // e.g. if `startRow` is S, and limit is L, then S / L + 1 = page number
  // 0 / 100 + 1 = 1 === page 1
  // 1 / 100 + 1 = 2 === page 2
  const page = Math.ceil(params.request.startRow / limit) + 1 // +1 because API is 1-based
  // then we access the column state to see which column is being sorted by and build the sort query string
  const sort = getSortQueryFromColumnState(params.api)

  // Finally, we construct a string representation of the grid's current filters by using getFilterModel()
  // and translating the filter instances in the model into the format specified by the API for each operation
  const filter = convertFilterModelToApiQuery(params.api.getFilterModel()) // ? returns => string
  return { limit, page, sort, filter } as EntityApiQueryParams
}

export function getServerSideGridData<ResponseType = any>(args: Args<ResponseType>, ...extraArgs: any[]) {
  return {
    getRows: async (params: IServerSideGetRowsParams) => {
      const {
        service,
        // Default function assignments to avoid null/undefined checks
        onSuccess = (_: any) => {},
        onError = (_: any) => {},
        transformParams = (args: ReturnType<typeof buildQueryParams>) => args,
        fieldArray,
        setResponseLength
      } = args

      params.api.showLoadingOverlay()

      const queryParams = transformParams(buildQueryParams(params))


      try {
        const response = await service.getByParams(queryParams)
        // With the server-side row model, AG Grid needs to know the total number of rows that can be fetched, which is specified with the rowCount parameter of the success function. We can use `totalCount` from the API response to determine the number of rows that can be fetched.
        
        params.api.hideOverlay()
        const onSuccessArgs = {
          queryParams,
          filterModel: params.api.getFilterModel(),
          response,
        }
        let responseData
        onSuccess(onSuccessArgs, ...extraArgs)
        // if (fieldArray && fieldArray.data.length > 0){
        //   const fieldArrayData = fieldArray.data.map((field: any) => field[fieldArray.key])
        //   const fieldArrayIds = fieldArray.data.map((field: any) => field.value); 
        //   // Filter out rows from the response data that are already in the fieldArray
        //   const filteredResponseData = response.data.filter((row: any) => !fieldArrayIds.includes(row.id));
        //   responseData = [...fieldArrayData, ...filteredResponseData]
        //   console.log(response.data, fieldArrayData, fieldArrayIds, filteredResponseData)
        // } else {
          responseData = response.data
        // }
        if(setResponseLength){
          setResponseLength(response.totalCount)
        }

        params.success({ rowData: responseData, rowCount: response.totalCount })
      } catch (error) {
        const onErrorArgs = {
          error: error as unknown as Error,
        }
        console.log(error)
        onError(onErrorArgs, ...extraArgs)
        params.api.hideOverlay()
        params.fail()
      }
    },
  }
}

export function getSortQueryFromColumnState(api: GridApi) {
  // const sortedColumns = columnApi.getColumnState().filter((col) => col.sort)
  const sortedColumns = api.getColumnState().filter((col) => col.sort)
  return sortedColumns ? sortedColumns.map((col) => (col.sort === 'asc' ? col.colId : `-${col.colId}`)).join(',') : undefined
}
