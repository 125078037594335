import { MarketingSource } from '@app/types'

type MarketingSourceOption = {
  label: string
  value: MarketingSource
}
export const formatMarketingSourceOption = (source: MarketingSource): MarketingSourceOption => {
  if (!source) return null
  return { label: source.source, value: source }
}
export const getMarketingSourceRequest = (source: MarketingSourceOption) => {
  if (source === undefined || source == null) return null
  return source.value
}
