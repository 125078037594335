import { SelectOption, SiteStatus } from '@app/types'

export const formatSiteStatusOption = (status: SiteStatus) => {
  if (status === undefined || status === null) return null
  switch (status) {
    case SiteStatus.ACTIVE:
      return { label: 'Active', value: SiteStatus.ACTIVE }
    case SiteStatus.INACTIVE:
      return { label: 'Inactive', value: SiteStatus.INACTIVE }
  }
}
export const getSiteStatusRequest = (source: SelectOption<SiteStatus>) => {
  if (source === undefined || source === null) return null
  return source.value
}
