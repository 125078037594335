import { CampaignFormData, CampaignRequest, CampaignResponse, Division, NewCampaignFormData, NewCampaignRequest } from '@app/types'

import { formatSingleCampaignENUM, formatSingleENUM } from './formUtilities'
import { formatContactOption, formatDivisionOption } from './selectOptions'
import { currencyToNumber } from './transformerUtilities'

export function prepareCampaignFormData(data: CampaignResponse, { divisions }: { divisions: Division[] }): CampaignFormData {
  // ? Currently, if the server returns an error when fetching the API Response, this function is the one that throws first
  if (data === undefined || data === null) throw new Error('Data is null or undefined')

  const { owner, type, focus, division: _division, status, ...rest } = data

  const division = divisions.find((d) => d.number === _division)

  return {
    ...rest,
    ownerId: owner ? formatContactOption(owner) : null,
    type: type ? formatSingleCampaignENUM(type) : null,
    focus: focus ? formatSingleCampaignENUM(focus) : null,
    division: division ? formatDivisionOption(division) : null,
    status: status ? formatSingleENUM(status) : null, // Should never be null because always backend sets status to ACTIVE by default on POST
    plannedCost: data.plannedCost || null,
    expectedRevenue: data.expectedRevenue || null,
  }
}

export function prepareNewCampaignRequest(data: NewCampaignFormData): NewCampaignRequest {
  const { ownerId, type, focus, division, ...rest } = data

  return {
    ...rest,
    ownerId: ownerId?.value,
    type: type ? type.value : null,
    focus: focus ? focus.value : null,
    division: division ? division.value : null,
    plannedCost: data.plannedCost ? currencyToNumber(data.plannedCost.toString()) : null,
    expectedRevenue: data.expectedRevenue || null,
  }
}

export function prepareCampaignRequest(data: CampaignFormData): CampaignRequest {
  const { ownerId, type, focus, division, status, expenses, ...rest } = data

  // ! Don't send expenses
  return {
    ...rest,
    ownerId: ownerId?.value,
    type: type ? type.value : null,
    focus: focus ? focus.value : null,
    division: division ? division.value : null,
    status: status ? status.value : null,
    plannedCost: data.plannedCost ? currencyToNumber(data.plannedCost.toString()) : null,
    expectedRevenue: data.expectedRevenue ? currencyToNumber(data.expectedRevenue.toString()) : null,
  }
}
