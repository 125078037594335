import { formatSalesRepOption } from '@app/utils'
import { ContactResponse } from './contacts'

export type SalesRepResponse = {
  id: string
  createdAt: Date
  updatedAt: Date
  name: string
  erpId: string
  employeeId: string
  type: SalesRepType
  contact: ContactResponse | null
}

export type SalesRepRequest = {
  name: string
  erpId: string
  employeeId?: string
  type: SalesRepType
}

export enum SalesRepType {
  TEAM = 'team',
  INDIVIDUAL = 'individual',
}

export type SalesRepSelectOption = ReturnType<typeof formatSalesRepOption>
