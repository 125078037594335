export function preventSheetClose(event: any): void {
const classList = event.target.classList
const hasMbxClass = Array.from(classList || []).some((className) => (className as string).includes('mbx'));
  if (
    event.target.parentNode.id === 'toast-close-trigger' ||
    event.target.closest('#toast-close-trigger') ||
    hasMbxClass
  ) {
    event.preventDefault()
  }
}
