import { NewExpenseFormData } from 'src/app-components/Create/Expense'

import { ExpenseFormData, ExpenseRequest, ExpenseResponse } from '@app/types'

import { formatCostCodeOption, formatPaymentMethodOption } from './formUtilities'
import { formatContactOption } from './selectOptions'

// ? Update these when cost code is updated in backend

export function prepareNewExpenseFormDataForRequest(data: NewExpenseFormData): ExpenseRequest {
  const { owner, costCode, paymentMethod, ...rest } = data

  return {
    ...rest,
    ownerId: owner ? owner.value : null,
    costCodeId: costCode ? costCode.value : null,
    paymentMethodId: paymentMethod ? paymentMethod.value : null,
  }
}

export function prepareExpenseFormData(data: ExpenseResponse): ExpenseFormData {
  // ? Currently, if the server returns an error when fetching the API Response, this function is the one that throws first
  if (data === undefined || data === null) throw new Error('Data is null or undefined')

  const { owner, costCode, paymentMethod, ...rest } = data

  return {
    ...rest,
    ownerId: owner ? formatContactOption(owner) : null,
    costCodeId: costCode ? formatCostCodeOption(costCode) : null,
    paymentMethodId: paymentMethod ? formatPaymentMethodOption(paymentMethod) : null,
  }
}

export function prepareExpenseRequest(data: ExpenseFormData): ExpenseRequest {
  const { ownerId, costCodeId, paymentMethodId, ...rest } = data

  return {
    ...rest,
    ownerId: ownerId ? ownerId.value : null,
    costCodeId: costCodeId ? costCodeId.value : null,
    paymentMethodId: paymentMethodId ? paymentMethodId.value.id : null,
  }
}
