import { emailGreetings } from '@app/constants'
import { AccountResponse, ContactResponse, EmailResponse, LeadResponse, OpportunityResponse, SiteResponse } from '@app/types'
export function generateGreeting(name: string) {
  const rand = Math.floor(Math.random() * emailGreetings.length)
  return emailGreetings[rand].replace('[Placeholder]', name)
}

type Entity = LeadResponse | AccountResponse | ContactResponse | OpportunityResponse | SiteResponse

export function formatEntityEmailData(obj: Entity) {
  const entityType = findEntityType(obj)
  const entityName = findEntityName(obj)
  const addresses = findEmailAddresses(obj)
  const entityMailTo = formatMailTo(entityType, entityName, addresses)

  return {
    type: entityType,
    name: entityName,
    addresses: addresses,
    mailto: entityMailTo,
    data: obj
  }
}

function formatMailTo(type: string, name: string, addresses: EmailResponse[]) {
  let subject, body, mailto
  if (type && name) {
    type === 'opportunity' ? (subject = `subject=${name}`) : (subject = '')
    type === 'lead' || type === 'contact' ? (body = `body=${generateGreeting(name)}`) : (body = '')
    addresses.length ? (mailto = `mailto:${addresses[0].address}${body !== '' ? `?${body}` : body}${subject !== '' ? `&${subject}` : subject}`) : ''
    return mailto
  }
  return ''
}

function findEmailAddresses(obj: Entity) {
  if ('emails' in obj) {
    // REVIEW: Why is this just not return obj.emails
    return obj.emails?.map((email) => email) || []
  }
  return []
}

function findEntityName(obj: Entity) {
  if ('name' in obj) {
    return obj.name
  }

  if ('firstName' in obj && 'lastName' in obj) {
    return `${obj.firstName} ${obj.lastName}`
  }

  return null
}

function findEntityType(obj: Entity) {
  function isAccount(obj: Entity): obj is AccountResponse {
    return 'accountStatus' in obj && !('owner' in obj)
  }

  function isLead(obj: Entity): obj is LeadResponse {
    return 'primaryRep' in obj && 'quality' in obj
  }

  function isContact(obj: Entity): obj is ContactResponse {
    return 'contactType' in obj
  }

  function isOpportunity(obj: Entity): obj is OpportunityResponse {
    return 'opportunityDescription' in obj
  }

  function isSite(obj: Entity): obj is SiteResponse {
    return 'owner' in obj
  }

  if (isAccount(obj)) {
    return 'account'
  } else if (isLead(obj)) {
    return 'lead'
  } else if (isContact(obj)) {
    return 'contact'
  } else if (isOpportunity(obj)) {
    return 'opportunity'
  } else if (isSite(obj)) {
    return 'site'
  }
  return null
}

export function parseAddressInMailto(mailTo: string): string[] | null {
  const regex = /[\w\.-]+@[\w\.-]+\.\w+/g
  return mailTo.match(regex)
}