import { CampaignResponse, ContactResponse, Division, LeadResponse, OpportunityResponse, SalesRepResponse, SiteResponse } from '@app/types'

export function formatCampaignOption(campaign: CampaignResponse) {
  return {
    label: campaign.name,
    value: campaign.id,
  }
}

export function formatDivisionOption(division: Division) {
  return {
    label: `(${division.number}) - ${division.name}`,
    value: division.number,
  }
}

export function formatContactOption(contact: ContactResponse) {
  return {
    label: `${contact.firstName} ${contact.lastName}`,
    value: contact.id,
    data: contact,
  }
}

export function formatOpportunityOption(opportunity: OpportunityResponse) {
  return {
    label: `${opportunity.name}`,
    value: opportunity.id,
    data: opportunity,
  }
}

export function formatSiteOption(site: SiteResponse) {
  return {
    label: site.name,
    value: site.id,
    site: site,
  }
}

export function formatLeadOption(lead: LeadResponse) {
  return {
    label: `${lead.firstName} ${lead.lastName}`,
    value: lead.id,
    data: lead,
  }
}

export function formatSalesRepOption(option: SalesRepResponse) {
  return {
    label: option.name,
    value: option.id,
    data: option,
  }
}
