import { ReactNode } from 'react'

export function usePlaceholder(input: string | ReactNode): string | ReactNode {
  const placeholder = '---'

  if (input !== undefined && input !== null && input !== '') {
    return input
  }
  return placeholder
}

export const placeholder = '---'
