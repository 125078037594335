import { ENDPOINTS } from '../constants/endpoints'

export function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const MAX_WIDTH = 1920,
  MAX_HEIGHT = 1920

export const resizeImage = (data: any) => {
  return new Promise((resolve) => {
    let blob = new Blob([data]),
      image = new Image()

    window.URL = window.URL || window.webkitURL
    image.src = window.URL.createObjectURL(blob)

    image.onload = () => {
      let canvas = document.createElement('canvas'),
        width = image.width,
        height = image.height

      if (width > height) {
        if (width > MAX_WIDTH) {
          height = Math.round((height *= MAX_WIDTH / width))
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width = Math.round((width *= MAX_HEIGHT / height))
          height = MAX_HEIGHT
        }
      }

      canvas.width = width
      canvas.height = height

      let ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0, width, height)

      canvas.setAttribute('style', 'position: absolute; top: -99999px; left: -99999px')
      canvas.setAttribute('id', 'CompressionPreview')
      document.body.appendChild(canvas)

      let result = canvas.toDataURL('image/jpeg', 0.9)

      document.getElementById('CompressionPreview').remove()

      resolve([result, convertDataURIToFile(result)])
    }
  })
}

export const convertDataURIToFile = (dataURI: any) => {
  let byteString

  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1])
  } else {
    byteString = unescape(dataURI.split(',')[1])
  }

  let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0],
    ia = new Uint8Array(byteString.length)

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  let blob = new Blob([ia], { type: mimeString })

  return new File([blob], `upload-${Date.now()}.jpeg`)
}

export async function addCard(requestFn: Function, data: ImageData) {
  const newCard = await requestFn({
    path: ENDPOINTS.LEADS_CARD,
    method: 'POST',
    body: JSON.stringify(data),
  })

  return newCard
}

export type ImageData = {
  image: string
}
