import {
  AccountFormData,
  AccountRequest,
  AccountResponse,
  ADDRESS_LABEL,
  BillingGroup,
  CountryType,
  Division,
  PhoneFormData,
  SelectOption,
  TaxExemptReason,
} from '@app/types'

import { OPTIONS } from '../constants/fieldOptions'
import { shippingProvidersMap } from '../constants/shippingProviders'
import {
  formatPaymentMethodOption,
  formatPhoneNumber,
  formatSingleENUM,
  formatStringOption,
  getAddressFormData,
  getAddressRequest,
  getNewAddress,
  getPhoneRequest,
  getSelectOptionFromBool,
  setAddressIndex,
  setPrimaryIndex,
} from './formUtilities'
import { formatContactOption, formatDivisionOption } from './selectOptions'
import { currencyToNumber } from './transformerUtilities'
import { formatMarketingSourceOption, getMarketingSourceRequest } from './options/marketingSource'

function getAccountStatusFormData(value: boolean) {
  return value ? { label: 'Active', value: true } : { label: 'Inactive', value: false }
}

export function getSpecialChargeFormData(value: string) {
  return OPTIONS.SPECIAL_CHARGE_OPTIONS.find((option) => option.value === value)
}

function getTaxEmptionFormData(value: string) {
  return OPTIONS.TAX_EXEMPTIONS.find((option) => option.value === value)
}

function getCreditAppStatusFormData(value: string) {
  return OPTIONS.CREDIT_APP_STATUS.find((option) => option.value === value)
}

export function formatBillingGroupOption(billingGroup: BillingGroup) {
  return { label: billingGroup.description, value: billingGroup }
}

function buildAddressesRequest(account: AccountFormData) {
  let newAddresses = [...account.addresses]

  // * If any addresses were missing a label, assign the 'Company Address' label as default
  // ? This is probably a safety check for bad data from ERP data imports
  newAddresses = account.addresses.map((address) => {
    if (address.label === '' || address.label === undefined || !address.label) {
      address.label = ADDRESS_LABEL.COMPANY_ADDRESS
    }
    return address
  })
  
  // * Iterate through the addresses and set the `isPrimary` property on one of them according to the value of `account.setPrimaryAddress`
  newAddresses = setAddressIndex(newAddresses, account.setPrimaryAddress, 'isPrimary')

  if (account.billToSameAsCompany === false) {
    newAddresses.push(account.billToAddress)
  }
  
  // Format addresses DTO for request and return the results
  return newAddresses?.length > 0 ? newAddresses.map(getAddressRequest) : []
}

export function prepareAccountFormDataForRequest(account: AccountFormData): AccountRequest {
  // console.log('pre', account)

  const {
    businessDevelopmentManager,
    accountPortfolioManager,
    insideSalesRep,
    setPrimaryPhone,
    setPrimaryAddress,
    primaryStakeholder,
    parentAccount,
    ...rest
  } = account

  const preppedPhones = setPrimaryIndex(account.phones, setPrimaryPhone)

  const addresses = buildAddressesRequest(account)

  const accountRequest: AccountRequest = {
    // SPREAD FIELDS THAT DON'T NEED TO BE TRANSFORMED
    ...rest,
    defaultBillTo: account.defaultBillTo?.value ? account.defaultBillTo?.value : null,
    creditCardSurchargeType: account.creditCardSurchargeType ? { type: 'Charge' } : { type: 'Exempt' },
    creditLimit: account.creditLimit ? currencyToNumber(account.creditLimit) : null,
    businessDevelopmentManagerId: account.businessDevelopmentManager?.value,
    accountPortfolioManagerId: account.accountPortfolioManager?.value,
    insideSalesRepId: account.insideSalesRep?.value,
    addOnTypes: account.addOnTypes?.map((addOnType) => ({ addOnType: addOnType.label, value: parseInt(addOnType.value) })) || [],
    accountManagementType: account.accountManagementType?.value,
    accountSell: account.accountSell?.value,
    parentAccountId: parentAccount?.value ? parentAccount.value : null,
    accountStatus: account.accountStatus?.value,
    addresses,
    apContactId: account.apContactId?.value || '',
    billingCurrency: account.billingCurrency?.value,
    billingGroup: account.billingGroup?.value,
    creditAppStatus: account.creditAppStatus?.value,
    electricTransmissionRepId: account.electricTransmissionRepId?.value,
    electricCustomerServiceRepId: account.electricCustomerServiceRepId?.value,
    electricApContactId: account.electricApContactId?.value,
    electricPaymentMethod: account.electricPaymentMethod?.value,
    electricSendInvoiceVia: account.electricSendInvoiceVia?.value,
    electricscheduleInvoice: account.electricscheduleInvoice?.value,
    holdPeriod: account.holdPeriod?.value,
    industries: account.industries
      ? account.industries?.map((industry) => {
          return { title: industry.value }
        })
      : [],
    lightingTransmissionRepId: account.lightingTransmissionRepId?.value,
    lightingCustomerServiceRepId: account.lightingCustomerServiceRepId?.value,
    lightingApContactId: account.lightingApContactId?.value,
    lightingPaymentMethod: account.lightingPaymentMethod?.value,
    lightingSendInvoiceVia: account.lightingSendInvoiceVia?.value,
    lightingscheduleInvoice: account.lightingscheduleInvoice?.value,
    marketingGroup: account.marketingGroup?.value,
    netTerms: account?.netTerms?.value,
    orderDisposition: account.orderDisposition?.value,
    phones: preppedPhones?.length > 0 ? preppedPhones?.map((phone) => getPhoneRequest(phone as PhoneFormData)) : [],
    shippingProvider: account.shippingProvider?.value,
    shippingNote: account.shippingNote ? account.shippingNote : null,
    source: getMarketingSourceRequest(account.source),
    specialCharge: account.specialCharge?.value,
    specialChargeAmount: account.specialChargeAmount ? currencyToNumber(account.specialChargeAmount) : null,
    statementPreference: account.statementPreference?.value.value,
    taxExemptReason: account.taxExemptReason?.value as TaxExemptReason,
    division: account.division?.value,
    primaryStakeholderId: account.primaryStakeholder?.value,
    invoiceCopies: account.invoiceCopies ? account.invoiceCopies : null,
  }

  // console.log('prepared request', accountRequest)
  return accountRequest
}

export function prepareAccountFormDataFromResponse(
  account: AccountResponse,
  { countries, divisions }: { countries: CountryType[]; divisions: Division[] },
): AccountFormData {

  const {
    apContact,
    electricApContact,
    electricCustomerServiceRep,
    electricPaymentMethod,
    electricTransmissionRep,
    lightingApContact,
    lightingCustomerServiceRep,
    lightingPaymentMethod,
    lightingTransmissionRep,
    addOnTypes,
    creditCardSurchargeType,
    creditAppStatus,
    netTerms,
    holdPeriod,
    taxID,
    division,
    primaryStakeholder,
    ...rest
  } = account

  const divOption = divisions.find((d) => d.number === division)

  // If a Bill To address is found, it means it should not be the same as the Company Address
  const existingBillToAddress = account.addresses.find(x => x.label === ADDRESS_LABEL.BILL_TO)

  const billToAddress = existingBillToAddress ? getAddressFormData(existingBillToAddress) : getNewAddress({
    label: formatSingleENUM(ADDRESS_LABEL.BILL_TO), 
    country: formatStringOption('United States of America'), 
    isBilling: true 
  })

  const formData: AccountFormData = {
    // SPREAD FIELDS THAT DON'T NEED TO BE TRANSFORMED
    ...rest,
    // * AR STATUS
    billToSameAsCompany: existingBillToAddress === undefined,
    billToAddress,
    // --------------------
    taxID: account.taxID ? account.taxID.replace(/(\d{2})(\d{7})/, '$1-$2') : null,
    creditCardSurchargeType: account.creditCardSurchargeType?.type === 'Charge' ? true : false,
    addOnTypes: account.addOnTypes?.map((obj) => ({ value: obj.value.toString(), label: obj.addOnType })) || [],
    defaultBillTo: account.defaultBillTo ? formatSingleENUM(account.defaultBillTo) : null,
    accountManagementType: account.accountManagementType ? { label: account.accountManagementType.type, value: account.accountManagementType } : null,
    accountSell: getSelectOptionFromBool('Yes', 'No', account.accountSell), // REVIEW >> #code-review--typescript
    parentAccount: account.parentAccount
      ? { value: account.parentAccount?.id, label: account.parentAccount?.name, data: account.parentAccount }
      : null,
    accountStatus: account.accountStatus || !account.accountStatus ? getAccountStatusFormData(account.accountStatus) : null,
    addresses: account.addresses.filter(x => x.label !== ADDRESS_LABEL.BILL_TO).map(getAddressFormData),
    setPrimaryAddress: account.addresses?.indexOf(account.addresses?.find((address) => address.isPrimary)),
    apContactId: apContact ? formatContactOption(apContact) : null,
    billingCurrency: account.billingCurrency ? formatStringOption(account.billingCurrency) : null,
    billingGroup: account.billingGroup ? formatBillingGroupOption(account.billingGroup) : null,
    creditAppStatus: creditAppStatus ? getCreditAppStatusFormData(creditAppStatus) : null,
    creditLimit: account.creditLimit ? account.creditLimit.toString() : null,
    electricTransmissionRepId: electricTransmissionRep ? formatContactOption(electricTransmissionRep) : null,
    electricCustomerServiceRepId: electricCustomerServiceRep ? formatContactOption(electricCustomerServiceRep) : null,
    electricApContactId: electricApContact ? formatContactOption(electricApContact) : null,
    electricPaymentMethod: electricPaymentMethod ? formatPaymentMethodOption(electricPaymentMethod) : null,
    electricSendInvoiceVia: account.electricSendInvoiceVia ? formatStringOption(account.electricSendInvoiceVia) : null,
    electricscheduleInvoice: account.electricscheduleInvoice ? formatStringOption(account.electricscheduleInvoice) : null,
    holdPeriod: holdPeriod ? { label: `${holdPeriod}`, value: holdPeriod } : null,
    industries: account.industries?.length
      ? account.industries?.map((industry) => ({ label: `${industry.title} (${industry.code})`, value: industry.title }))
      : null,
    lightingTransmissionRepId: lightingTransmissionRep ? formatContactOption(lightingTransmissionRep) : null,
    lightingCustomerServiceRepId: lightingCustomerServiceRep ? formatContactOption(lightingCustomerServiceRep) : null,
    lightingApContactId: lightingApContact ? formatContactOption(lightingApContact) : null,
    lightingPaymentMethod: lightingPaymentMethod ? formatPaymentMethodOption(lightingPaymentMethod) : null,
    lightingSendInvoiceVia: account.lightingSendInvoiceVia ? formatStringOption(account.lightingSendInvoiceVia) : null,
    lightingscheduleInvoice: account.lightingscheduleInvoice ? formatStringOption(account.lightingscheduleInvoice) : null,
    marketingGroup: account.marketingGroup ? { label: account.marketingGroup.description, value: account.marketingGroup } : null,
    netTerms: netTerms ? formatStringOption(netTerms) : null,
    orderDisposition: account.orderDisposition ? { label: account.orderDisposition.value, value: account.orderDisposition } : null,
    phones: account.phones.map((phone) => formatPhoneNumber(phone, { countries })),
    setPrimaryPhone: account.phones?.indexOf(account.phones?.find((phone) => phone.isPrimary)),
    shippingProvider: account.shippingProvider ? shippingProvidersMap.get(account.shippingProvider.toLowerCase()) : null,
    shippingNote: account.shippingNote ? account.shippingNote : null,
    source: formatMarketingSourceOption(account.source),
    specialCharge: account.specialCharge ? formatSingleENUM(account.specialCharge) : null,
    specialChargeAmount: account.specialChargeAmount ? account.specialChargeAmount.toString() : '0',
    statementPreference: account.statementPreference ? { label: account.statementPreference.value, value: account.statementPreference } : null,
    taxExemptReason: account.taxExemptReason ? formatSingleENUM(account.taxExemptReason) : null,
    businessDevelopmentManager: account.businessDevelopmentManager ? formatContactOption(account.businessDevelopmentManager) : null,
    insideSalesRep: account.insideSalesRep ? formatContactOption(account.insideSalesRep) : null,
    accountPortfolioManager: account.accountPortfolioManager ? formatContactOption(account.accountPortfolioManager) : null,
    division: divOption ? formatDivisionOption(divOption) : null,
    primaryStakeholder: account.primaryStakeholder ? formatContactOption(account.primaryStakeholder) : null,
  }

  return formData
}
