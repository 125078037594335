import { NewLeadFormData } from 'src/app-components/Create/Lead'

import { AddressFormData, AddressResponse, CountryType, Division, LeadFormData, LeadRequest, LeadResponse, PhoneFormData } from '@app/types'

import {
  formatPhoneNumber,
  formatSingleENUM,
  getAddressFormData,
  getAddressRequest,
  getPhoneRequest,
  removeEmptyId,
  setAddressIndex,
  setPrimaryIndex,
} from './formUtilities'
import { formatCampaignOption, formatContactOption, formatDivisionOption } from './selectOptions'
import { currencyToNumber } from './transformerUtilities'

/*Note - applicableFSGServices -> services (change in types also)
functionalRoles -> jobFunctions
remove website
*/

export function prepareNewLeadDataForRequest(lead: NewLeadFormData) {
  const { notes, primaryRep, primaryEmail, ...leadData } = lead

  const newPhoneData = {
    label: 'Phone',
    number: lead.phone,
    callingCode: lead.callingCode,
    isPrimary: true,
    extension: lead.extension,
  }

  const preparedNewLeadRequest = {
    ...leadData,
    phones: lead.phone ? [getPhoneRequest(newPhoneData)] : null,
    emails: lead.primaryEmail ? [{ address: lead.primaryEmail, label: 'Email', isPrimary: true }] : null,
    primaryRepId: lead.primaryRep.value ? lead?.primaryRep.value : null,
    division: lead.division ? lead.division?.value : null,
  }

  return preparedNewLeadRequest
}

export function prepareLeadFormDataForRequest(lead: LeadFormData) {
  // console.log('input', lead)
  const { setPrimaryAddress, setPrimaryEmail, setPrimaryPhone, ...rest } = lead
  const preppedPhones = setPrimaryIndex(lead.phones, setPrimaryPhone)
  const preppedEmails = setPrimaryIndex(lead.emails, setPrimaryEmail)
  const preppedAddresses = setAddressIndex(lead.addresses, setPrimaryAddress, 'isPrimary')
  const preparedLeadRequest: LeadRequest = {
    ...rest,
    // fields that need to be transformed per request schema:
    timeFrame: lead.timeFrame ? { timeFrame: lead.timeFrame.label, id: lead.timeFrame.value } : null,
    salesType: lead.salesType ? lead.salesType?.value : null,
    quality: lead.quality?.value,
    services: lead.services?.map((service) => service.value),
    industries:
      lead.industries?.map((industry) => {
        return { title: industry.value }
      }) || [],
    addresses: preppedAddresses?.length > 0 ? preppedAddresses.map(getAddressRequest) : [],
    emails: preppedEmails?.length > 0 ? preppedEmails.map(removeEmptyId) : [],
    phones: preppedPhones?.length > 0 ? preppedPhones?.map((phone) => getPhoneRequest(phone as PhoneFormData)) : [],
    webLinks: lead.webLinks.map(removeEmptyId),
    status: lead.status?.value,
    jobFunctions: lead.jobFunctions
      ? lead.jobFunctions.map((jobFunction) => ({ type: jobFunction.type, subType: jobFunction.subType, function: jobFunction.function }))
      : [],
    primaryRepId: lead.primaryRep?.value,
    source: lead.source ? { source: lead.source.value } : null,
    campaignId: lead.campaignId ? lead.campaignId.value : null,
    division: lead.division ? lead.division?.value : null,
    estimatedValue: lead.estimatedValue? currencyToNumber(lead.estimatedValue) : null,
  }

  // console.log('prepared request: ', preparedLeadRequest)

  return preparedLeadRequest
}

export function prepareLeadResponseForFormData(lead: LeadResponse, { countries, divisions }: { countries: CountryType[], divisions: Division[] }): LeadFormData {
  const division = divisions?.find((d) => d.number === lead.division)
  const preparedLeadFormData: LeadFormData = {
    ...lead,
    // fields that need to be transformed per form data schema:
    timeFrame: lead.timeFrame ? { label: lead.timeFrame.timeFrame, value: lead.timeFrame.id } : null,
    salesType: lead.salesType ? formatSingleENUM(lead.salesType) : null,
    phones: lead.phones.map((phone) => formatPhoneNumber(phone, { countries })),
    setPrimaryPhone: lead.phones?.indexOf(lead.phones?.find((phone) => phone.isPrimary)),
    setPrimaryEmail: lead.emails?.indexOf(lead.emails?.find((email) => email.isPrimary)),
    setPrimaryAddress: lead.addresses?.indexOf(lead.addresses?.find((address) => address.isPrimary)),
    quality: lead.quality?.rating ? { label: lead.quality.rating, value: lead.quality.rating } : null,
    services: lead.services?.map((service) => ({ label: service.serviceType, value: service.serviceType })),
    industries: lead.industries?.map((industry) => ({ label: `(${industry.code}) ${industry.title}`, value: industry.title })),
    addresses: lead.addresses.map(getAddressFormData),
    status: lead.status?.status ? { label: lead.status.status, value: lead.status.status } : null,
    jobFunctions: lead.jobFunctions.map((jobFunction) => ({
      label: jobFunction.function,
      value: jobFunction.function,
      subType: jobFunction.subType,
      function: jobFunction.function,
      type: jobFunction.type,
    })),
    source: lead.source?.source ? { label: lead.source.source, value: lead.source.source } : null,
    primaryRep: lead.primaryRep ? formatContactOption(lead.primaryRep) : null,
    campaignId: lead.campaign ? formatCampaignOption(lead.campaign) : null,
    division: division ? formatDivisionOption(division) : null,
    estimatedValue: lead.estimatedValue? lead.estimatedValue : null,
  }

  // console.log('prepared form data: ', preparedLeadFormData)
  return preparedLeadFormData
}
