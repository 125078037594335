import { Children, PropsWithChildren } from 'react'

import { ButtonV2, Popover, PopoverContent, PopoverTrigger } from '@fsg/gui-bits'
import { cn } from '@fsg/utils'

type CellListProps = PropsWithChildren & {
  className?: string
}

export function CellListWithPopover({ children, className }: CellListProps) {
  let displayedChildren = Children.count(children) > 1 ? Children.toArray(children).slice(0, 2) : children
  const remainingChildren = Children.count(children) > 2 ? Children.toArray(children).slice(2) : null

  return (
    <div className={cn(className, 'space-x-xs')}>
      {displayedChildren}
      {remainingChildren ? (
        <Popover>
          <PopoverTrigger asChild>
            <ButtonV2 variant="secondary" size="sm">
              +{remainingChildren.length}
            </ButtonV2>
          </PopoverTrigger>
          <PopoverContent className="flex flex-col gap-sm">{remainingChildren}</PopoverContent>
        </Popover>
      ) : null}
    </div>
  )
}
