import { QualityRating } from '@app/types'

export function formatQualityRatingOption(options: QualityRating[]) {
  //explicit icon & class casting, with default fallback
  const QUALITY_RATING = [
    { value: 'Hot', icon: 'Fire', classes: 'text-white bg-color-error' },
    { value: 'Warm', icon: 'Coffee', classes: 'text-white bg-color-warning' },
    { value: 'Cold', icon: 'Snowflake', classes: 'text-white bg-color-primary-light' },
    { value: 'Dead', icon: 'Jollyroger', classes: 'text-white bg-true-black' },
  ]

  const formattedOptions = options.map((option) => {
    const match = QUALITY_RATING.find((iconOption) => iconOption.value === option.rating)

    if (match) {
      return {
        label: option.rating,
        value: option.rating,
        icon: match.icon,
        classes: match.classes,
      }
    }
    return {
      label: option.rating,
      value: option.rating,
      icon: 'fallback', //formatIconOptionLabel util will handle this icon
      classes: 'text-white bg-gray-darker',
    }
  })
  return formattedOptions
}
