const BASE_LEAD = '/leads'
const BASE_ACCOUNT = '/accounts'
const BASE_CONTACT = '/contacts'
const BASE_OPPORTUNITITY = '/opportunities'
const BASE_SITE = '/sites'
const BASE_CAMPAIGN = '/campaigns'

export type RouteFunction = ((id: string, id2?: string) => string)

//added a base across each route (since they change from entity to entity) that can be referenced statically. i didn't want to break any other functionality, so this should be additive as opposed to destructive

export const routes = {
  leads: BASE_LEAD,
  lead: {
    general: (id: string) => `${BASE_LEAD}/${id}/general`,
    base: (id: string) => `${BASE_LEAD}/${id}/general`,
  },

  accounts: BASE_ACCOUNT,
  account: {
    general: (id: string) => `${BASE_ACCOUNT}/${id}/general`,
    basicInformation: (id: string) => `${BASE_ACCOUNT}/${id}/general/basic-information`,
    base: (id: string) => `${BASE_ACCOUNT}/${id}/general/basic-information`,
  },

  contacts: BASE_CONTACT,
  contact: {
    basicInformation: (id: string) => `${BASE_CONTACT}/${id}/basic-information`,
    base: (id: string) => `${BASE_CONTACT}/${id}/basic-information`,
  },

  opportunities: BASE_OPPORTUNITITY,
  opportunity: {
    basicInformation: (id: string) => `${BASE_OPPORTUNITITY}/${id}/general/basic-information`,
    base: (id: string) => `${BASE_OPPORTUNITITY}/${id}/general/basic-information`,
  },
  sites: BASE_SITE,
  site: {
    general: (id: string) => `${BASE_SITE}/${id}/general`,
    base: (id: string) => `${BASE_SITE}/${id}/general`,
  },
  campaigns: BASE_CAMPAIGN,
  campaign: {
    base: (id: string) => `${BASE_CAMPAIGN}/${id}/general`,
    general: (id: string) => `${BASE_CAMPAIGN}/${id}/general`,
    expenses: (id: string) => `${BASE_CAMPAIGN}/${id}/expenses`,
    results: (id: string) => `${BASE_CAMPAIGN}/${id}/results`,
    expense: (campaignId: string, expenseId: string) => `${BASE_CAMPAIGN}/${campaignId}/expenses/${expenseId}`,
  },
}
