export function formatOptionLabel(option: any, OptionLabelMeta: any) {
  const { context } = OptionLabelMeta || { context: 'response' }
  if (context === 'menu') {
    return (
      <div>
        <div className="font-bold">{option.label}</div>
        <div>{option.description}</div>
      </div>
    )
  } else {
    return option.label
  }
}
