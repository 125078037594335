'use client'

import { useEffect, useState } from 'react'

import { UseFormReturn } from 'react-hook-form'

export function useBeforeUnload(form: UseFormReturn) {
  const { dirtyFields, isDirty, touchedFields } = form.formState
  const [dirtied, setDirtied] = useState(Object.keys(dirtyFields).length)
  useEffect(() => {
    //watcher function for dirtied fields change
    // if (Object.keys(dirtyFields).length !== dirtied) {
    //   setDirtied(Object.keys(dirtyFields).length)
    //   console.log(Object.keys(dirtyFields).length, { dirtyFields, isDirty, touchedFields })
    // }

    function handleBeforeUnload(event: BeforeUnloadEvent) {
      if (isDirty && dirtyFields && Object.keys(dirtyFields).length > 0) {
        const confirmationMessage = 'Changes you made may not be saved.'
        event.returnValue = confirmationMessage
        return confirmationMessage
        //trying out returning a value instead of prevent default. prevention may not be enough to trigger the unload warning
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [dirtyFields, dirtied, isDirty, form.formState, touchedFields])
}
