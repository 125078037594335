export function setPrimaryField(
  array: any,
  index: number,
  formPrimaryIndex: number,
  formField: string,
  setFunction: (field: string, value: number) => void,
): void {
  if (index === formPrimaryIndex) {
    setFunction(formField, 0)
  } else if (formPrimaryIndex >= array.length || formPrimaryIndex < array.length) {
    setFunction(formField, formPrimaryIndex - 1)
  }
}
