import { ACTIVITY_ENTITIES, ACTIVITY_NOTE, ACTIVITY_TYPES, ENDPOINTS } from '../constants'

const { ACTIVITIES } = ENDPOINTS

export const getActivities = async (requestFn: Function, entity: ACTIVITY_ENTITIES, id: string) => {
  const activity = await requestFn({
    path: `${ACTIVITIES(entity, id)}?limit=1000`,
  })
  return activity
}

export const addActivity = async (requestFn: Function, entity: ACTIVITY_ENTITIES | string, id: string, activityType: ACTIVITY_TYPES) => {
  const newActivity = await requestFn({
    path: ACTIVITIES(entity, id),
    method: 'POST',
    body: JSON.stringify({
      activityType: activityType.toString(),
    }),
  })

  return newActivity
}

export const addEmailActivity = (requestFn: Function, entity: ACTIVITY_ENTITIES | string, id: string) =>
  addActivity(requestFn, entity, id, ACTIVITY_TYPES.EMAIL)


export const addPhoneCallActivity = (requestFn: Function, entity: ACTIVITY_ENTITIES | string, id: string) =>
addActivity(requestFn, entity, id, ACTIVITY_TYPES.PHONE_CALL)


export const addStatusChangeActivity = (requestFn: Function, entity: ACTIVITY_ENTITIES | string, id: string) =>
  addActivity(requestFn, entity, id, ACTIVITY_TYPES.STATUS_CHANGE)


export const addMeetingActivity = (requestFn: Function, entity: ACTIVITY_ENTITIES | string, id: string) =>
addActivity(requestFn, entity, id, ACTIVITY_TYPES.MEETING)


export const addViewActivity = (requestFn: Function, entity: ACTIVITY_ENTITIES | string, id: string) =>
  addActivity(requestFn, entity, id, ACTIVITY_TYPES.VIEW_ONLY)


export const addEditActivity = (requestFn: Function, entity: ACTIVITY_ENTITIES | string, id: string) =>
addActivity(requestFn, entity, id, ACTIVITY_TYPES.EDIT)


export const addActivityNote = async (requestFn: Function, activityId: string, content: string) => {
  const newNote = await requestFn({
    path: ACTIVITY_NOTE(activityId),
    method: 'POST',
    body: JSON.stringify({
      content,
    }),
  })

  return newNote
}
