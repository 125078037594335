import { useNotification } from '@fsg/gui-bits';
import { useCallback } from 'react';

export function useEmailClipboard() {
  const notification = useNotification();

  const showCopiedMessage = useCallback(() => {
    notification.api.notify({
      type: 'success',
      message: 'Copied email to clipboard!',
      duration: 3000,
    });
  }, [notification]);

  const showErrorMessage = useCallback(() => {
    notification.api.notify({
      type: 'alert',
      message: 'Could not copy!',
      duration: 3000,
    });
  }, [notification]);

  const copyEmailToClipboard = useCallback(
    async (emailAddress: string | string[]) => {
      if (!navigator?.clipboard) {
        showErrorMessage();
        console.error('Clipboard API is not available');
        return;
      }

      const recipient = typeof emailAddress === 'object' ? emailAddress.join(',') : emailAddress

      try {
        await navigator.clipboard.writeText(recipient);
        showCopiedMessage();
      } catch (err) {
        console.error('Failed to copy: ', err);
        showErrorMessage();
      }
    },
    [showCopiedMessage, showErrorMessage]
  );

  return copyEmailToClipboard;
}
