import { Coffee, Fire, Jollyroger, Snowflake } from '@fsg/icons'
import { cn } from '@fsg/utils'

import { IconOption } from '@app/types'
import { LucideCoffee, LucideFlame, LucideSkull, LucideSnowflake } from 'lucide-react'

export function formatLeadQualityOption(option: IconOption) {

  const Icon = (): any => {
    return getIcon(option.label)
  }
  return (
    <div className={cn('flex items-center gap-3xs')}>
      <Icon />
      {option.label}
    </div>
  )
}

export const getIcon = (label: string) => {
  const iconClass = 'w-[14px]'

  switch (label) {
    case 'Warm':
      return <LucideCoffee className={iconClass} />
    case 'Hot':
      return <LucideFlame className={iconClass} />
    case 'Dead':
      return <LucideSkull className={iconClass} />
    case 'Cold':
      return <LucideSnowflake className={iconClass} />
    default:
      return null
  }
}